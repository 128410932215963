import React, { useState } from "react";
import './Cadastro.css';
import TelefoneBrasileiroInput from "react-telefone-brasileiro";
import Cep from "react-simple-cep-mask";
//import ComboBox from 'react-responsive-combo-box';
//import 'react-responsive-combo-box/dist/index.css';
import { useNavigate } from "react-router-dom";
import api from '../services/api';
import SelectCustom from '../components/SelectCustom';

function Cadastro() {


  document.body.classList.replace(document.body.className,'cadform');

  const [email, setEmail] = useState("");
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cep, setCep] = useState("");
  const [senha, setSenha] = useState("");
  const [senhac, setSenhac] = useState("");
  const [tipo, setTipo] = useState("");
  const [retws, setRetws] = useState("");

  const options = [
    'Vidraceiro',
    'Distribuidor AL',
    'Serralheiro',
    'Outros'
  ]

  const Resposta = () => {
    if (retws === "") return null;
  
    return (
      <label className="retlabels">
            <h5 className="retlabels">{retws}</h5>
      </label>
    );
  };

  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = '../cadastroconcluido'; 
    navigate(path);
  };
  
  
  async function cadastrar(){

    if (email === "" | nome === "" | sobrenome === "" | empresa === "" | 
        telefone === "" | cep === "" | senha === "" | senhac === "" |
        tipo === ""){
      setRetws("Atenção! preencha todas as informações");
      return;
    }

    if (telefone.length < 13 
        | telefone === "(00)0000-0000"
        | telefone === "(11)1111-1111"
        | telefone === "(00)00000-0000"
        | telefone === "(11)11111-1111"){
      setRetws("Número de telefone inválido!");
      return;
    }

    if (cep.length < 9
      | cep === "00000-000"
      | cep === "11111-111"){
      setRetws("Número de CEP inválido!");
      return;
    }

    if (senha !== senhac){
      setRetws("As senhas não conferem!");
      setSenha("");
      setSenhac("");
      return;
    }

    try {
      /* 
      {
            "Nome": "Leonardo",
            "Sobrenome": "Scudeiro",
            "Email": "leonardo.scudeiro@gmail.com",
            "Password": "Leolanzi94",
            "ConfirmPassword": "Leolanzi94",
            "Empresa": "Teste",
            "Telefone": "(11) 98356-1163",
            "Cep": "09350-550",
            "Tipo": "1",
            "AcceptTerms": true
      }
      */
      const response = await api.post('/accounts/register',{
        "Nome": nome,
        "Sobrenome": sobrenome,
        "Email": email,
        "password": senha,
        "ConfirmPassword": senhac,
        "Empresa": empresa,
        "Telefone": telefone,
        "Cep": cep,
        "Tipo": tipo,
        "AcceptTerms": true
      });

      //console.log(response);
      setRetws("");
      routeChange();
    } catch (error) {
      //console.log(error);
      setRetws(error.response.data.message);
    }
  }

  return (
      <main className="cadform">
        <div className="cadquad">
          <h2 className="cadtitulo">Criar sua conta</h2>

          <label className="cadlabels">
            <strong className="cadlabels">Nome</strong>
          </label>

          <input className="cadinputs"
            value={nome}
            onChange={(event) => setNome(event.target.value)}
            type="text"
            placeholder="Informe seu nome"
          />

          <label className="cadlabels">
            <strong className="cadlabels">Sobrenome</strong>
          </label>

          <input className="cadinputs"
            value={sobrenome}
            onChange={(event) => setSobrenome(event.target.value)}
            type="text"
            placeholder="Informe seu sobrenome"
          />

          <label className="cadlabels">
            <strong className="cadlabels">Tipo de negócio</strong>
          </label>

          <div className="caddivselect">
          <SelectCustom
              options={options}
              onItemSelected={setTipo}
              defaultValue={tipo}
          />
          </div>

          <label className="cadlabels">
            <strong className="cadlabels">Empresa</strong>
          </label>

          <input className="cadinputs"
            value={empresa}
            onChange={(event) => setEmpresa(event.target.value)}
            type="text"
            placeholder="Informe o nome da empresa"
          />

          <label className="cadlabels">
            <strong className="cadlabels">Telefone</strong>
          </label>

          <TelefoneBrasileiroInput className="cadinputs"
            value={telefone}
            onChange={(event) => setTelefone(event.target.value)}
            temDDD
            placeholder="(00)0000-0000"
          />

          <label className="cadlabels">
            <strong className="cadlabels">Cep</strong>
          </label>

          <Cep className="cadinputs"
            value={cep}
            onChange={ (cep) => setCep(cep)}
            placeholder="00000-000"
          />

          <label className="cadlabels">
               <strong className="cadlabels">E-mail</strong>
          </label>

          <input className="cadinputs"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            type="text"
            placeholder="Informe o seu e-mail"
          />

          <label className="cadlabels">
            <strong className="cadlabels">Senha</strong>
          </label>

          <input className="cadinputs"
            value={senha}
            onChange={(event) => setSenha(event.target.value)}
            type="password" 
            name="password" 
            placeholder="Informe a senha"
          />  

          <label className="cadlabels">
            <strong className="cadlabels">Confirmar senha</strong>
          </label>

          <input className="cadinputs"
            value={senhac}
            onChange={(event) => setSenhac(event.target.value)}
            type="password" 
            name="password" 
            placeholder="confirme a senha"
          />  

          <Resposta />

          <button className="cadbtn" onClick={cadastrar}>
            CRIAR CONTA
          </button>
        </div> 
      </main>
  );
}

export default Cadastro;
