import React from 'react'
import './Principal.css'
import Header from '../components/Header'
import { Routes, Route } from 'react-router-dom'
import Projetos from './Projetos'
import Novoprojeto from './Novoprojeto'
import Escolhaproduto from './Escolhaproduto'
import Meusdados from './Meusdados'

function Principal() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Projetos />} />
        <Route path="/novoprojeto" element={<Novoprojeto />} />
        <Route path="/escolhaproduto" element={<Escolhaproduto />} />
        <Route path="/meusdados" element={<Meusdados />} />
        <Route path="/projetos" element={<Projetos />} />
      </Routes>
    </>
  )
}
export default Principal