import React, { useState, Keyboard} from 'react';
//import ReactDOM from 'react-dom/client';
import './SelectButton.css';

const SelectButtonItem = ({ value, onItemClick}) => {
    return <li onClick={() => onItemClick(value)}>{value}</li>;
  };
  
  const SelectButton = ({ options, onItemSelected, defaultValue}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");
  
    const handleMouseEnter = () => {
      setIsOpen(true);
    };
  
    const handleMouseLeave = () => {
      setIsOpen(false);
    };
  
    const handleItemClick = (value) => {
      setIsOpen(false);
      onItemSelected(value);
    };
  
    return (
      <div
        className="select-button"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <input
          placeholder="Escolha a opção"
          value={defaultValue}
          readOnly={true}
          onKeyPress={(event) => {
            if (1===1) {
              event.preventDefault();
            }
          }}
          autoComplete='off'
          onChange={(e) => setSelectedValue(e.target.value)}
        />
        {isOpen && (
          <ul>
            {options.map((item) => (
              <SelectButtonItem
                key={item}
                value={item}
                onItemClick={handleItemClick}
              />
            ))}
          </ul>
        )}
      </div>
    );
  };

export default SelectButton;
