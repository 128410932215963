import React, { useState, useEffect }  from 'react';
import styled from 'styled-components';
import './Novoprojeto.css';
//import ComboBox from 'react-responsive-combo-box';
//import 'react-responsive-combo-box/dist/index.css';
import api from '../services/api';
import Token from '../services/Token';
import { saveAs } from 'file-saver';
import {useNavigate} from "react-router-dom";
import SelectCustom from '../components/SelectCustom';
import SelectButton from '../components/SelectButton';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const Button = styled.button`
  color: #fff;
  background: #005aab;
  font-size: 1em;
  margin: 0.7em;
  padding: 0.25em 1em;
  border: 2px solid #005aab;
  border-radius: 3px;
  height: 40px;
`;

function Novoprojeto(){

 /* function teste(){
  alert(espessuravidro);
  }; */
  const navigate = useNavigate();
  const [produtobase, setProdutobase] = useState("");
  const [prodbase, setProdbase] = useState(0);
  const [cliente, setCliente] = useState("");
  const [obra, setObra] = useState("");
  const [espessuravidro, setEspessuravidro] = useState("");
  const [instalacao, setInstalacao] = useState("");
  const [alturapux, setAlturapux] = useState(null);
  const [entrefuros, setEntrefuros] = useState(null);
  const [cor, setCor] = useState("");
  const [largura, setLargura] = useState(null);
  const [altura, setAltura] = useState(null);
  const [folhas, setFolhas] = useState(null);
  const [quantidade, setQuantidade] = useState(null);
  const [vidrodet, setVidrodet] = useState("");
  const [puxador, setPuxador] = useState("");
  const [obs, setObs] = useState("");
  const [retws, setRetws] = useState("");
  const [textopux, setTextopux] = useState("Puxador:");
  const [textoaltpux, setTextoaltpux] = useState("Altura da fechadura (mm):");
  const [lado, setLado] = useState("Esquerdo");

  const [espessuraoptions, setEspessuraoptions] = useState([]);
  //const [instalacaooptions, setInstalacaooptions] = useState([]);
  const [coroptions, setCoroptions] = useState([]);
  const [vidrosoptions, setVidrosoptions] = useState([]);
  const [puxadoroptions, setPuxadoroptions] = useState([]);
  const [instalacaooptions, setInstalacaooptions] = useState([]);

  const queryParams = new URLSearchParams(window.location.search);
  const produto = queryParams.get('produto');
  const projeto = queryParams.get('projeto');
   //DADOS OBTER DA API
  const [data, setData]  = useState(JSON.parse('[{"ID": null,"DATAPEDIDO": "","OBRA": "","ITEM": "","OBS": ""}]'));
  const [isStatus, setStatus] = useState(true);

  const projetosoptions = [
    'MaxSystem - Janela',
    'MaxSystem - Porta',
    'MaxSystem - Pivô'
  ]

  const ladoptions = [
    'Esquerdo',
    'Direito'
  ]

  function Novoprojeto(valor) {
    var iprod;
    if (valor==="MaxSystem - Janela"){
      iprod = '1';
    }
    else if (valor==="MaxSystem - Porta"){
      iprod = '2';
    }
    else if (valor==="MaxSystem - Pivô"){
      iprod = '3';
    }
    let path = '/novoprojeto?produto='+iprod+'&cliente='+cliente+'&obra='+obra; 
    setStatus(!isStatus);
    consultarproj();
    consultarproduto(iprod);
    setProdbase(iprod);
    setFolhas("");
    setQuantidade(0);
    setAltura("");
    setLargura("");
    setEspessuravidro("");
    setInstalacao("");
    setAlturapux("");
    setEntrefuros(0);
    setPuxador("");
    setVidrodet("");
    setObs("");
    setCor("");
    navigate(path);
    
  }

  async function consultarproj(){
    const token = sessionStorage.getItem('token');
    const queryParams = new URLSearchParams(window.location.search);
    const obrabase = queryParams.get('obra');
    const clientebase = queryParams.get('cliente');
    try {
      Token();
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const response = await api.post('projetos/consulta',{
        "D1": "",
        "D2": "",
        "Cliente": clientebase,
        "Obra": obrabase,
        "Status": "Ativo"
      }, config);
      const bakery = response.data[0];
      if (bakery){
        setData(JSON.parse(JSON.stringify(response.data)));
      }
      else
      {
        setData(JSON.parse('[{"ID": null,"DATAPEDIDO": "","CLIENTE": "","OBRA": "","ITEM": "","OBS": "","STATUS": ""}]'));
      }
      //console.log("Executando consulta");
      //setData(JSON.parse('[{"ID": 1,"DATAPEDIDO": "2023-05-08T00:00:00","OBRA": "Teste","ITEM": "JANELA MAX SYSTEM largura do vão: 2000mm altura do vão: 1000mm folhas: 2 cor: Branco","OBS": "Obs de teste"},{"ID": 2,"DATAPEDIDO": "2023-05-08T00:00:00","OBRA": "Teste","ITEM": "JANELA MAX SYSTEM largura do vão: 2000mm altura do vão: 1000mm folhas: 2 cor: Branco","OBS": "Obs de teste"}]'));
    } catch (error) {
      //console.log((error.response.data.message));
    }
  }

  //INICIO TABELA

  const Head = ({ keys, head}) => {
    return(
      <Thead>
          <Tr>
            {
              keys.map(key => <Th key={key}>{head[key] || key}</Th>)
            }
          </Tr>  
      </Thead>
    )  
  }
  
  const Row = ({ record }) => {
    const keys = Object.keys(record)
    return(
      <Tr key={record.id}>
        {
          keys.map(key => <Td key={key}>{record[key]}</Td>)
        }
      </Tr> 
    )
  }
  
  const TableC = ({data, head}) => {
    const keys = Object.keys(data[0])
    return(
      <Table>
        <Head keys={keys} head={head}>
        </Head>
        <Tbody>
          {data.map(record => <Row record={record} />) }
        </Tbody>
      </Table>
    )
  }
  //FIM TABELA

  //CHAMADA TABELA

  function TableExample(props) {

    const head = {
      ID: 'Nº',
      DATAPEDIDO: 'Data',
      CLIENTE: 'Cliente',
      OBRA: 'Obra',
      ITEM: 'Item',
      OBS: 'Observações',
      STATUS: 'Status'
    }
    return(
      <TableC data={data} head={head} />
    )
  }
  //FIM CHAMADA TABELA

  function setPuxador2(valor){
    setPuxador(valor);
    if (valor==="Sem puxador"){
      setEntrefuros(0);
    }
  }

  function setProdutobase2(valor){
    setProdutobase(valor);
    setQuantidade(1);
    if (valor==="PORTA PIVOTANTE MAX SYSTEM"){
      setFolhas(1);
    }
    if (valor==="JANELA MAX SYSTEM"){
      setTextopux("Acessórios:");
      setTextoaltpux("Altura do bate fecha (mm):");
      setEntrefuros(0);
    }
    else if (valor==="PORTA PIVOTANTE MAX SYSTEM"){
      setTextopux("Fechadura:");
      setTextoaltpux("Altura da fechadura (mm):");
    }
    else{
      setTextopux("Puxador:");
      setTextoaltpux("Altura da fechadura (mm):");
    }
  }

  async function visualizar(){
    const qParams = new URLSearchParams(window.location.search);
    const param = qParams.get('projeto');
    if (param !== null) {
      const token = sessionStorage.getItem('token');
      try {
        Token();
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        //console.log("Executando consulta");
        const response = await api.get('projetos/projeto/'+param, config);
        
        const fileAsBase64 = response.data;
        //console.log(fileAsBase64);
        const binaryString = window.atob(fileAsBase64);
        const bytes = new Uint8Array(binaryString.length);


        const file = new Blob([bytes.map((byte, i) => binaryString.charCodeAt(i))]);

        saveAs(file, 'Projeto'+param+'.pdf');

        /* const file = new Blob([response.data], {
          type: 'application/pdf',
        });
        
        const fileURL = URL.createObjectURL(file);
        
        window.open(fileURL); */
      } catch (error) {
        console.log((error.response.data.message));
      }
    }
  };

  async function visualizar2(){
    const qParams = new URLSearchParams(window.location.search);
    const param = qParams.get('projeto');
    if (param !== null) {
      const token = sessionStorage.getItem('token');
      try {
        Token();
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        //console.log("Executando consulta");
        const response = await api.get('projetos/projetovidros/'+param, config);
        const fileAsBase64 = response.data;
        //console.log(fileAsBase64);
        const binaryString = window.atob(fileAsBase64);
        const bytes = new Uint8Array(binaryString.length);
        const file = new Blob([bytes.map((byte, i) => binaryString.charCodeAt(i))]);
        saveAs(file, 'Projeto'+param+'_vidros.pdf');
      } catch (error) {
        console.log((error.response.data.message));
      }
      try {
        Token();
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        //console.log("Executando consulta");
        const response = await api.get('projetos/projetoprodutos/'+param, config);
        const fileAsBase64 = response.data;
        //console.log(fileAsBase64);
        const binaryString = window.atob(fileAsBase64);
        const bytes = new Uint8Array(binaryString.length);
        const file = new Blob([bytes.map((byte, i) => binaryString.charCodeAt(i))]);
        saveAs(file, 'Projeto'+param+'_produtos.pdf');
      } catch (error) {
        console.log((error.response.data.message));
      }
    }
  };

  async function consultarproduto(produtoid){

      if (produtoid !== null) {
        setProdbase(produtoid);
        console.log(produtoid);
        if (produtoid===3){
          setFolhas(1);
        }
        const token = sessionStorage.getItem('token');
        try {
          Token();
          const config = {
            headers: { Authorization: `Bearer ${token}` }
          };
          //console.log("Executando consulta");
          const response = await api.get('produtosbase/'+produtoid, config);

          setProdutobase2(JSON.parse(JSON.stringify(response.data))[0].Descricao);
        
          var count = 0;
          var i = 0;
          var valor = "";
          var array = [];
          
          //produtoscores
          count = Object.keys(JSON.parse(JSON.stringify(response.data))[0].Produtoscores).length;
          while (i < count) {
            valor = JSON.parse(JSON.stringify(response.data))[0].Produtoscores[i].Descricao;
            array.push(valor);
            i++;
          }
          setCoroptions(array);

          //Produtosvidros
          array = [];
          i = 0;
          count = Object.keys(JSON.parse(JSON.stringify(response.data))[0].Produtosvidros).length;
          while (i < count) {
            valor = JSON.parse(JSON.stringify(response.data))[0].Produtosvidros[i].Descricao;
            array.push(valor);
            i++;
          }
          setEspessuraoptions(array);

          //Produtosvidrosdet
          array = [];
          i = 0;
          count = Object.keys(JSON.parse(JSON.stringify(response.data))[0].Produtosvidrosdet).length;
          while (i < count) {
            valor = JSON.parse(JSON.stringify(response.data))[0].Produtosvidrosdet[i].Descricao;
            array.push(valor);
            i++;
          }
          setVidrosoptions(array);

          //puxadoroptions
          array = [];
          i = 0;
          count = Object.keys(JSON.parse(JSON.stringify(response.data))[0].Produtospuxadores).length;
          while (i < count) {
            valor = JSON.parse(JSON.stringify(response.data))[0].Produtospuxadores[i].Descricao;
            array.push(valor);
            i++;
          }
          setPuxadoroptions(array);

          //opcoesinstalacaooptions
          array = [];
          i = 0;
          count = Object.keys(JSON.parse(JSON.stringify(response.data))[0].Produtosopcoes).length;
          while (i < count) {
            valor = JSON.parse(JSON.stringify(response.data))[0].Produtosopcoes[i].Descricao;
            array.push(valor);
            i++;
          }
          setInstalacaooptions(array);

        } catch (error) {
          //console.log((error.response.data.message));
        }
      }
  }

  useEffect(() => {
    let ignore = false;
    const queryParamsini = new URLSearchParams(window.location.search);
    const produtoini = queryParamsini.get('produto');
    const projetoini  = queryParamsini.get('projeto');
    const obraini  = queryParamsini.get('obra');
    const clienteini  = queryParamsini.get('cliente');

    consultarproj();

    async function consultarprojeto(){
      const token = sessionStorage.getItem('token');
      if (!token){
        navigate('/login');
      }
      if (projetoini !== null) {
        try {
          Token();
          const config = {
            headers: { Authorization: `Bearer ${token}` }
          };
          //console.log("Executando consulta");
          const response = await api.get('projetos/'+projetoini, config);
          
          //console.log(response.data);
          setFolhas(response.data.folhas);
          setQuantidade(response.data.quantidade);
          consultarproduto(response.data.produtosbaseId);
          setAltura(response.data.altura);
          setLargura(response.data.largura);
          setObra(response.data.obra);
          setEspessuravidro(response.data.espessuravidro);
          setInstalacao(response.data.opcaoinstalacao);
          setAlturapux(response.data.alturapux);
          setEntrefuros(response.data.entrefuros);
          setPuxador(response.data.puxador);
          setVidrodet(response.data.vidrodet);
          setCliente(response.data.cliente);
          setObs(response.data.obs);
          setProdbase(response.data.produtosbaseId);
          setCor(response.data.cor);
          setLado(response.data.lado);
          
  
        } catch (error) {
          //console.log((error.response.data.message));
        }
      }
      else{
        if (obraini !== null){
          setObra(obraini);
        }
        if (clienteini !== null){
          setCliente(clienteini);
        }
      }
    }

    
    if (!ignore)  {
      consultarproduto(produtoini);
      consultarprojeto();
    }
    return () => { ignore = true; }
    },[isStatus]
  );  

  async function salvarprojeto(){
    setRetws("");
    const queryParams = new URLSearchParams(window.location.search);
    const obrabasesave = queryParams.get('obra');
    const clientebasesave = queryParams.get('cliente');
    if (produtobase === "" | cliente === "" | espessuravidro === "" | cor === "" | 
        obra === "" | largura === null | altura === null | folhas === null | quantidade === null | 
        vidrodet === ""){
        setRetws("Atenção! É necessário preencher todas as informações");
        verificaForm();
        return;
    }

    if (prodbase === 0) {
      setRetws("Atenção! É necessário preencher todas as informações");
      verificaForm();
      return;
    }

    if (alturapux===null){
      setAlturapux(0);
    }

    if (projeto !== null) {
      const token = sessionStorage.getItem('token');
      try {
        Token();
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        //console.log("Executando consulta");

        const response = await api.put('projetos/'+projeto,{
          "ProdutosbaseId": prodbase,
          "Cliente": cliente,
          "Espessuravidro": espessuravidro,
          "Opcaoinstalacao": instalacao,
          "Alturapux": alturapux,
          "Entrefuros": entrefuros,
          "Cor": cor,
          "Obra": obra,
          "Largura": largura,
          "Altura": altura,
          "Folhas": folhas,
          "Quantidade": quantidade,
          "Vidrodet": vidrodet,
          "Puxador": puxador,
          "Obs": obs,
          "Lado": lado
        }, config);

        setRetws(response.data.message);

      } catch (error) {
        //console.log((error.response.data.message));
        setRetws(error.response.data.message);
      }
    }
    else {
      const token = sessionStorage.getItem('token');
      try {
        Token();
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        //console.log("Executando consulta");
        const response = await api.post('projetos/novoprojeto',{
          "ProdutosbaseId": produto,
          "Cliente": cliente,
          "Espessuravidro": espessuravidro,
          "Opcaoinstalacao": instalacao,
          "Alturapux": alturapux,
          "Entrefuros": entrefuros,
          "Cor": cor,
          "Obra": obra,
          "Largura": largura,
          "Altura": altura,
          "Folhas": folhas,
          "Quantidade": quantidade,
          "Vidrodet": vidrodet,
          "Puxador": puxador,
          "Obs": obs,
          "Lado": lado
        }, config);

        setRetws(response.data.message);
        var path = "";
        if (response.data.obra !== "" || response.data.cliente !== ""){
            path = '/novoprojeto?projeto='+response.data.id+'&cliente='+response.data.cliente+'&obra='+response.data.obra;
        } 
        else if (response.data.obra !== ""){
            path = '/novoprojeto?projeto='+response.data.id+'&obra='+response.data.obra;
        }
        else if (response.data.cliente  !== ""){
            path = '/novoprojeto?projeto='+response.data.id+'&cliente='+response.data.cliente;
        }
        else {
            path = '/novoprojeto?projeto='+response.data.id; 
        }
        setStatus(!isStatus);
        navigate(path);
      } catch (error) {
        //console.log((error.response.data.message));
        setRetws(error.response.data.message);
      }
    }
  }

  const Resposta = () => {  
    return (
      <label className="novoprojetolabels2">
            <h5 className="novoprojetolabels2">{retws}</h5>
      </label>
    );
  };

  /*
  const Folhas = () => {
      return(
        <div classname='novoprojetodiv'>
          <label className="novoprojetolabels">
          <strong className="novoprojetolabels">Quantidade de folhas:</strong>
          </label>
          <input id="folhas" className="novoprojetoinputsnum"
            value={folhas}
            onChange={(event) => setFolhas(event.target.value)}
            type="text"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            placeholder="Qtd de folhas"
          />
        </div>
      )
  } */
  
  document.body.classList.replace(document.body.className,'novoprojetobodyn');

  function verificaForm() {
    /* if (puxador === "") {
      document.getElementById('puxador').focus();
      document.getElementById("puxador").style.background = "rgb(235, 235, 148)";
    }
    if (cor === "") {
      document.getElementById('cor').focus();
      document.getElementById("cor").style.background = "rgb(235, 235, 148)";
    }
    if (vidrodet === "") {
      document.getElementById('vidrodet').focus();
      document.getElementById("vidrodet").style.background = "rgb(235, 235, 148)";
    }
    if (espessuravidro === "") {
      document.getElementById('espessuravidro').focus();
      document.getElementById("espessuravidro").style.background = "rgb(235, 235, 148)";
    } */
    if (folhas === null) {
      document.getElementById('folhas').focus();
      document.getElementById("folhas").style.background = "rgb(235, 235, 148)";
    }
    if (quantidade === null) {
      document.getElementById('quantidade').focus();
      document.getElementById("quantidade").style.background = "rgb(235, 235, 148)";
    }
    if (altura === null) {
      document.getElementById('altura').focus();
      document.getElementById("altura").style.background = "rgb(235, 235, 148)";
    }
    if (largura === null) {
      document.getElementById('largura').focus();
      document.getElementById("largura").style.background = "rgb(235, 235, 148)";
    }
    if (obra === "") {
      document.getElementById('obra').focus();
      document.getElementById("obra").style.background = "rgb(235, 235, 148)";
    }
    if (cliente === "") {
        document.getElementById('nome').focus();
        document.getElementById("nome").style.background = "rgb(235, 235, 148)";
    }

    //if (instalacao === "") {
    //  document.getElementById('instalacao').focus();
    //  document.getElementById("instalacao").style.background = "rgb(235, 235, 148)";
    // }
    
    return true;
  }

  const Botaovis = () =>{
    const queryParini = new URLSearchParams(window.location.search);
    const existeprojeto  = queryParini.get('projeto');
    if (existeprojeto){
      return(
        <Button type="button" onClick={visualizar}>VISUALIZAR COMPLETO</Button>
      )
    }
    else{
        return(
          null
      )
    }
  }

  const Botaovis2 = () =>{
    const queryParini = new URLSearchParams(window.location.search);
    const existeprojeto  = queryParini.get('projeto');
    if (existeprojeto){
      return(
        <Button type="button" onClick={visualizar2}>VISUALIZAR SEPARADO</Button>
      )
    }
    else{
        return(
          null
      )
    }
  }

  const Botaoincluir= () =>{
    const queryParini = new URLSearchParams(window.location.search);
    const existeprojeto  = queryParini.get('projeto');
    if (existeprojeto){
      return(
        <SelectButton
              options={projetosoptions}
              onItemSelected={Novoprojeto}
              defaultValue={"INCLUIR NO MESMO PROJETO"}
        />
      )
    }
    else{
        return(
          null
      )
    }
  }

  const Verprojetos = () =>{
    const queryParini = new URLSearchParams(window.location.search);
    const existeobra  = queryParini.get('obra');
    const existecliente  = queryParini.get('cliente');
    if (existeobra || existecliente){
      return(
        
          <div className='novoprojetotab'>
            <TableExample >
            </TableExample>
          </div>
      )
    }
    else{
        return(
          null
      )
    }
  }

  const Verprojetostitulo = () =>{
    const queryParini = new URLSearchParams(window.location.search);
    const existeobra  = queryParini.get('obra');
    const existecliente  = queryParini.get('cliente');
    if (existeobra || existecliente){
      return(
        <h2 className='novoprojetotitulo'>Itens da mesma obra/cliente:</h2>
      )
    }
    else{
        return(
          null
      )
    }
  }

  return (
    <form className='novoprojetomain' autoComplete='off'>
    <h2 className='novoprojetotitulo2'>Importante</h2>
    <h4 className='novoprojetoalerta'>Medição técnica dos vãos a serem instalados os produtos da linha MaxSystem, são de total responsabilidade do profissional vidreiro (vidraceiro ou serralheiro);</h4>
    <h4 className='novoprojetoalerta'>Interferência máxima dos vãos em relação a desnível e prumo são: altura 15mm e largura 10mm;</h4>
    <h4 className='novoprojetoalerta'>Na altura sempre a MAIOR medida deverá ser considerada para utilizar no aplicativo e na largura deverá ser utilizado a MENOR das medidas;</h4>
    

    <h2 className='novoprojetotitulo'>Dados básicos</h2>
    <div classname='novoprojetodiv'>
      <label className="novoprojetolabels">
      <strong className="novoprojetolabels">Produto</strong>
      </label>
      <input className="novoprojetoinputs" readOnly
        value={produtobase}
        type="text"
        placeholder="Produto base"
        autoComplete='off'
      />
    </div>

    <div classname='novoprojetodiv'>
      <label className="novoprojetolabels">
      <strong className="novoprojetolabels">Cliente</strong>
      </label>
      <input id="nome" className="novoprojetoinputs"
        value={cliente}
        onChange={(event) => setCliente(event.target.value)}
        type="text"
        placeholder="Informe o nome do cliente"
        autoComplete='off'
      />
    </div>

    <div classname='novoprojetodiv'>
      <label className="novoprojetolabels">
      <strong className="novoprojetolabels">Obra</strong>
      </label>
      <input id="obra" required className="novoprojetoinputs"
        value={obra}
        onChange={(event) => setObra(event.target.value)}
        type="text"
        placeholder="Informe a obra/projeto"
        autoComplete='off'
      />
    </div>

    <h2 className='novoprojetotitulo'>Detalhamento</h2>

    <div classname='novoprojetodiv'>
          <label className="novoprojetolabels">
          <strong className="novoprojetolabels">Espessura:</strong>
          </label>
          <SelectCustom
              options={espessuraoptions}
              onItemSelected={setEspessuravidro}
              defaultValue={espessuravidro}
          />
    </div>

    <div classname='novoprojetodiv'>
          <label className="novoprojetolabels">
          <strong className="novoprojetolabels">Tipo de vidro:</strong>
          </label>
          <SelectCustom
              options={vidrosoptions}
              onItemSelected={setVidrodet}
              defaultValue={vidrodet}
          />
    </div>

    <div classname='novoprojetodiv'>
          <label className="novoprojetolabels">
          <strong className="novoprojetolabels">Qtd. (mesmo vão):</strong>
          </label>
          <input id="quantidade" className="novoprojetoinputsnum"
            value={quantidade}
            onChange={(event) => setQuantidade(event.target.value)}
            type="text"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            placeholder="Qtd de itens p/ mesmo vão"
            autoComplete='off'
          />
     </div>

     <div classname='novoprojetodiv'>
          <label className="novoprojetolabels">
          <strong className="novoprojetolabels">Opção de instalação:</strong>
          </label>
          <SelectCustom
              options={instalacaooptions}
              onItemSelected={setInstalacao}
              defaultValue={instalacao}
          />
    </div>

    {vidrodet==="Guardian Reflect" && (instalacao ==="Simples 1F" || instalacao ==="1 Fixo lateral + Fechadura lateral") && <div classname='novoprojetodiv'>
          <label className="novoprojetolabels">
          <strong className="novoprojetolabels">Lado:</strong>
          </label>
          
          <SelectCustom
              options={ladoptions}
              onItemSelected={setLado}
              defaultValue={lado}
          />
    </div>}

    <div classname='novoprojetodiv'>
      <label className="novoprojetolabels">
      <strong className="novoprojetolabels">Largura do vão (mm):</strong>
      </label>
      <input id="largura" className="novoprojetoinputsnum"
        value={largura}
        onChange={(event) => setLargura(event.target.value)}
        type="text"
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        placeholder="Indique a largura"
        autoComplete='off'
      />
    </div>

    <div classname='novoprojetodiv'>
      <label className="novoprojetolabels">
      <strong className="novoprojetolabels">Altura do vão (mm):</strong>
      </label>
      <input id="altura" className="novoprojetoinputsnum"
        value={altura}
        onChange={(event) => setAltura(event.target.value)}
        type="text"
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        placeholder="Indique a altura"
        autoComplete='off'
      />
    </div>

    <div classname='novoprojetodiv'>
          <label className="novoprojetolabels">
          <strong className="novoprojetolabels">Quantidade de folhas:</strong>
          </label>
          <input id="folhas" className="novoprojetoinputsnum"
            value={folhas}
            onChange={(event) => setFolhas(event.target.value)}
            type="text"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            placeholder="Qtd de folhas"
            autoComplete='off'
          />
    </div>

    <div classname='novoprojetodiv'>
          <label className="novoprojetolabels">
          <strong className="novoprojetolabels">Cor do perfil/acessórios:</strong>
          </label>
          <SelectCustom
              options={coroptions}
              onItemSelected={setCor}
              defaultValue={cor}
          />
    </div>

    <div classname='novoprojetodiv'>
          <label className="novoprojetolabels">
          <strong className="novoprojetolabels">{textopux}</strong>
          </label>
          <SelectCustom
              options={puxadoroptions}
              onItemSelected={setPuxador2}
              defaultValue={puxador}
          />
    </div>

    <div classname='novoprojetodiv'>
          <label className="novoprojetolabels">
          <strong className="novoprojetolabels">{textoaltpux}</strong>
          </label>
          <input id="alturapux" className="novoprojetoinputsnum"
            value={alturapux}
            onChange={(event) => setAlturapux(event.target.value)}
            type="text"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            placeholder="Indique a altura"
            autoComplete='off'
          />
    </div>

    <div classname='novoprojetodiv'>
          <label className="novoprojetolabels">
          <strong className="novoprojetolabels">Medida entre furos (mm):</strong>
          </label>
          <input id="entrefuros" className="novoprojetoinputsnum"
            value={entrefuros}
            onChange={(event) => setEntrefuros(event.target.value)}
            type="text"
            disabled={puxador==="Sem puxador" | produtobase==="JANELA MAX SYSTEM"}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            placeholder="Indique a medida"
            autoComplete='off'
          />
    </div>

    <h2 className='novoprojetotitulo'>Observações</h2>

    <div classname='novoprojetodiv'>
      <textarea className="novoprojetoinputsobs"
        value={obs}
        onChange={(event) => setObs(event.target.value)}
        type="text"
        placeholder="Escreva qualquer observação relevante ao projeto"
        autoComplete='off'
      />
    </div>
   
    <Resposta />
    <div classname='novoprojetodiv'>
      <Button type="button" onClick={salvarprojeto}>SALVAR PROJETO</Button>
    </div>

    <div classname='novoprojetodiv'>
      <Botaovis />
    </div>

    <div classname='novoprojetodiv'>
      <Botaovis2 />
    </div>

    <div classname='novoprojetodiv'>
      <Botaoincluir />
    </div>

    <Verprojetostitulo/>


    <Verprojetos />

    </form>
  )
}
export default Novoprojeto