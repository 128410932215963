import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import './Meusdados.css';
import TelefoneBrasileiroInput from "react-telefone-brasileiro";
import Cep from "react-simple-cep-mask";
//import ComboBox from 'react-responsive-combo-box';
//import 'react-responsive-combo-box/dist/index.css';
import api from '../services/api';
import Token from '../services/Token';
import SelectCustom from '../components/SelectCustom';
import {useNavigate} from "react-router-dom";
import ImageUploading from 'react-images-uploading';

const Button = styled.button`
  color: #fff;
  background: #005aab;
  font-size: 1em;
  margin: 1.5em;
  padding: 0.25em 1em;
  border: 2px solid #005aab;
  border-radius: 3px;
  height: 40px;
`;

function Meusdados(){

  document.body.classList.replace(document.body.className,'meusdadosmainbody');

  const [email, setEmail] = useState("");
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cep, setCep] = useState("");
  const [senha, setSenha] = useState("");
  const [senhac, setSenhac] = useState("");
  const [tipo, setTipo] = useState("");
  const [retws, setRetws] = useState("");
  const [retwssenha, setRetwssenha] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [images, setImages] = React.useState([]);
  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  const options = [
    'Vidraceiro',
    'Distribuidor AL',
    'Serralheiro',
    'Outros'
  ]

  const navigate = useNavigate();

  const Resposta = () => {  
    return (
      <label className="meusdadoslabels2">
            <h5 className="meusdadoslabels2">{retws}</h5>
      </label>
    );
  };

  const Respostasenha = () => {
    return (
      <label className="meusdadoslabels2">
            <h5 className="meusdadoslabels2">{retwssenha}</h5>
      </label>
    );
  };

  useEffect(() => {
    async function consultardados(){
      const token = sessionStorage.getItem('token');
      const id = sessionStorage.getItem('id');
      if (!token){
        navigate('/login');
      }
      try {
        Token();
        const headers = { 'Authorization': 'Bearer '+token };
        const response = await api.get('/accounts/'+id,{headers});
        setRetws("");
        setRetwssenha("");
        //(response);
        setEmail(response.data.email);
        setNome(response.data.nome);
        setSobrenome(response.data.sobrenome);
        setEmpresa(response.data.empresa);
        setTelefone(response.data.telefone);
        setCep(response.data.cep);
        setTipo(response.data.tipo);
      } catch (error) {
        //console.log((error.response.data.message));
      }
    }

    let ignore = false;
    
    if (!ignore)  consultardados()
    return () => { ignore = true; }
    },[]
  );

  async function atualizarcadastro(){
    setRetws("");
    if (email === "" | nome === "" | sobrenome === "" | empresa === "" | 
        telefone === "" | cep === "" | tipo === ""){
      setRetws("Atenção! preencha todas as informações");
      return;
    }

    if (telefone.length < 13 
        | telefone === "(00)0000-0000"
        | telefone === "(11)1111-1111"
        | telefone === "(00)00000-0000"
        | telefone === "(11)11111-1111"){
      setRetws("Número de telefone inválido!");
      return;
    }

    if (cep.length < 9
      | cep === "00000-000"
      | cep === "11111-111"){
      setRetws("Número de CEP inválido!");
      return;
    }

    try {
      /* 
      {
            "Nome": "Leonardo",
            "Sobrenome": "Scudeiro",
            "Email": "leonardo.scudeiro@gmail.com",
            "Empresa": "Teste",
            "Telefone": "(11) 98356-1163",
            "Cep": "09350-550",
            "Tipo": "1",
            "AcceptTerms": true
      }
      */
     
      const token = sessionStorage.getItem('token');
      const id = sessionStorage.getItem('id');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      if (images[0]){
        const response = await api.put('accounts/'+id,{
          "Nome": nome,
          "Sobrenome": sobrenome,
          "Email": email,
          "Empresa": empresa,
          "Telefone": telefone,
          "Cep": cep,
          "Tipo": tipo,
          "AcceptTerms": true,
          "Imagem": images[0].data_url
        }, config);
      }
      else
      {
        const response = await api.put('accounts/'+id,{
          "Nome": nome,
          "Sobrenome": sobrenome,
          "Email": email,
          "Empresa": empresa,
          "Telefone": telefone,
          "Cep": cep,
          "Tipo": tipo,
          "AcceptTerms": true,
          "Imagem": ""
        }, config);
      }
      

      //console.log(response);
      setRetws('Os dados cadastrais foram atualizados!');
    } catch (error) {
      //console.log(error);
      setRetws(error.response.data.message);
    }
  }

  async function atualizarsenha(){

    if (senha === "" | senhac === ""){
      setRetws("Atenção! preencha a senha e a confirmação da senha");
      return;
    }
    
    if (senha !== senhac){
      setRetwssenha("As senhas não conferem!");
      setSenha("");
      setSenhac("");
      return;
    }

    try {
      const token = sessionStorage.getItem('token');
      const id = sessionStorage.getItem('id');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const response = await api.put('accounts/'+id,{
        "Password": senha,
        "ConfirmPassword": senhac
      }, config);

      //console.log(response);
      setRetwssenha('A sua senha foi alterada!');
    } catch (error) {
      //console.log(error);
      setRetwssenha(error.response.data.message);
    }
  }

  return (
    <main className='meusdadosmain' autoComplete='off'>
    
    <h2 className='meusdadostitulo'>Editar seus dados</h2>
    <div classname='meusdadosdiv'>
      
      <label className="meusdadoslabels">
      <strong className="meusdadoslabels">Nome</strong>
      </label>

      <input className="meusdadosinputs"
        value={nome}
        onChange={(event) => setNome(event.target.value)}
        type="text"
        placeholder="Informe seu nome"
        autoComplete='off'
      />
    </div>

    <div classname='meusdadosdiv'>
      <label className="meusdadoslabels">
        <strong className="meusdadoslabels">Sobrenome</strong>
      </label>

      <input className="meusdadosinputs"
        value={sobrenome}
        onChange={(event) => setSobrenome(event.target.value)}
        type="text"
        placeholder="Informe seu sobrenome"
        autoComplete='off'
      />
    </div>

    <div classname='meusdadosdiv'>
      <label className="meusdadoslabels">
        <strong className="meusdadoslabels">Tipo de negócio</strong>
      </label>

      <SelectCustom
        options={options}
        onItemSelected={setTipo}
        defaultValue={tipo}
      />

    </div>

    <div classname='meusdadosdiv'>
      <label className="meusdadoslabels">
        <strong className="meusdadoslabels">Empresa</strong>
      </label>

      <input className="meusdadosinputs"
        value={empresa}
        onChange={(event) => setEmpresa(event.target.value)}
        type="text"
        placeholder="Informe o nome da empresa"
        autoComplete='off'
      />
    </div>

    <div classname='meusdadosdiv'>
      <label className="meusdadoslabels">
        <strong className="meusdadoslabels">Telefone</strong>
      </label>

      <TelefoneBrasileiroInput className="meusdadosinputs"
        value={telefone}
        onChange={(event) => setTelefone(event.target.value)}
        temDDD
        placeholder="(00)0000-0000"
        autoComplete='off'
      />
    </div>

    <div classname='meusdadosdiv'>
      <label className="meusdadoslabels">
        <strong className="meusdadoslabels">Cep</strong>
      </label>

      <Cep className="meusdadosinputs"
        value={cep}
        onChange={ (cep) => setCep(cep)}
        placeholder="00000-000"
      />
    </div>

    <div classname='meusdadosdiv'>
      <label className="meusdadoslabels">
          <strong className="meusdadoslabels">E-mail</strong>
      </label>

      <input className="meusdadosinputs"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        type="text"
        placeholder="Informe o seu e-mail"
        autoComplete='off'
      />
    </div>

    <h2 className='meusdadostitulo'>Logo</h2>

    <div className="meusdadosdiv">
      <ImageUploading
        maxFileSize={5000000}
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        acceptType={['jpg']}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <button
              style={isDragging ? { color: 'red' } : undefined}
              onClick={onImageUpload}
              {...dragProps}
            >
              CLIQUE P/ ANEXAR IMAGEM
            </button>
            &nbsp;
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image['data_url']} alt="" width="100" />
                <div className="image-item__btn-wrapper">
                  <button onClick={() => onImageUpdate(index)}>Modificar</button>
                  <button onClick={() => onImageRemove(index)}>Remover</button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    </div>

    <Resposta />

    <Button onClick={atualizarcadastro}>ATUALIZAR MEUS DADOS</Button>

    <h2 className='meusdadostitulo'>Alterar minha senha</h2>

    <div classname='meusdadosdiv'>
      <label className="meusdadoslabels">
        <strong className="meusdadoslabels">Nova senha</strong>
      </label>

      <input className="meusdadosinputs"
        value={senha}
        onChange={(event) => setSenha(event.target.value)}
        type="password" 
        name="password" 
        placeholder="Informe a senha"
      />  
    </div>

    <div classname='meusdadosdiv'>
      <label className="meusdadoslabels">
        <strong className="meusdadoslabels">Confirmar senha</strong>
      </label>

      <input className="meusdadosinputs"
        value={senhac}
        onChange={(event) => setSenhac(event.target.value)}
        type="password" 
        name="password" 
        placeholder="confirme a senha"
      />  
    </div>

    <Respostasenha />
     
    <Button onClick={atualizarsenha}>ALTERAR MINHA SENHA</Button>

    <h2 /* className='meusdadostitulo'>Exclusão de conta</h2>
    <label className="meusdadoslabels2">
        <h5 className="meusdadoslabels2">*A exclusão de conta é irreversível e todos os seus dados (incluindo os projetos) serão excluídos permanentemente</h5>
    </label>
    <Button>SOLICIAR EXCLUSAO DE CONTA</Button>  ---> */> </h2>
  </main>
  )
}
export default Meusdados