import React, { useState } from "react";
import {BiLogIn} from 'react-icons/bi'
import './Login.css';
import logo from '../logo.png';
import {Link, useNavigate} from "react-router-dom";
import api from '../services/api';
/* import  secureLocalStorage  from  "react-secure-storage"; */
  
function Login() {

  /* nao funcionando bem
document.addEventListener("keypress", function(e) {
    if(e.key === 'Enter') {
      autenticar();    
    }
}); */
  
document.body.classList.replace(document.body.className,'loginform');

const navigate = useNavigate();
const [email, setEmail] = useState("");
const [senha, setSenha] = useState("");
const [retws, setRetws] = useState("");

function setToken(userToken) {
    sessionStorage.setItem('token', userToken);
    var d1 = new Date ();
    var d2 = new Date ( d1 );
    d2.setMinutes ( d1.getMinutes() + 110 );
    sessionStorage.setItem('tokendatahora', d2);
};

function setId(id) {
  sessionStorage.setItem('id', id)
};

function setNome(nome) {
  sessionStorage.setItem('nome', nome)
};


async function autenticar(){
    setRetws("");
    
    if (email === ""){
      setRetws("Informe o email!");
      return;
    };

    if (senha === ""){
      setRetws("Informe a senha!");
      return;
    };

    try {
      const response = await api.post('/accounts/authenticate',{
        "Email": email,
        "password": senha
      });

      //console.log(response);
      setId(response.data.id);
      setNome(response.data.nome);
      setToken(response.data.jwtToken);
      let path = '/projetos'; 
      navigate(path);
      //window.location.href = '/projetos';

    } catch (error) {
      setRetws(error.response.data.message);
    }
  }; 

  const Resposta = () => {
    if (retws === "") return null;
  
    return (
      <label className="retlabelslogin">
            <h5 className="retlabelslogin">{retws}</h5>
      </label>
    );
  };

  return (
      <main className="loginform">
        <div className="loginquad">
          <img className="loginlogo" src={logo} alt="Logo" />
          <h2 className="logintitulo">Login</h2>
          <label className="loginlabels">
               <strong className="loginlabels">E-mail</strong>
          </label>
          <input className="logininputs"
          type="email"
          placeholder="Informe o seu e-mail"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          />
          <label className="loginlabels">
               <strong className="loginlabels">Senha</strong>
          </label>
          <input className="logininputs"
          type="password" 
          name="password" 
          onChange={(event) => setSenha(event.target.value)}
          placeholder="Informe a senha"
          />    

          <Resposta />

          <Link className="recsenhalink" to="/recuperarsenha">Esqueci minha senha</Link>

          <button className="loginbtn" onClick={autenticar}>
            ACESSAR
          <BiLogIn size={25} color="#fff"/>
          </button> 
          <div className="logincadastro">
            <h4 className="loginlabels">Não tem uma conta?</h4>
            <Link className="loginlink" to="/cadastro">Criar conta</Link>
          </div>
        </div> 
      </main>
  );
}

export default Login;
