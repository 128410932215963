import React from 'react';
import './Erro.css';
import logo from '../logo.png';

function Erro() {
  
  document.body.classList.replace(document.body.className,'form');
/*
  function teste(){
    //alert(sessionStorage.getItem('token'));
    //alert(sessionStorage.getItem('tokendatahora'));
    alert(selecionado);
    Token();
  }

  const options = [
    'Vidraceiro',
    'Distribuidor AL',
    'Serralheiro',
    'Outros'
  ] 

  const [selecionado, setSelecionado] = useState("Outros"); */

  return (
      <main className="form">
          <img className="logo" src={logo} alt="Logo" />
          <h1 className="titulo">PÁGINA NÃO ENCONTRADA!</h1>
      </main>
  );
}

export default Erro;
