import React, { useState } from "react";
import './Login.css';
import logo from '../logo.png';
import {useNavigate} from "react-router-dom";
import api from '../services/api';
/* import  secureLocalStorage  from  "react-secure-storage"; */
  
function Recuperarsenha() {

document.body.classList.replace(document.body.className,'loginform');

const [email, setEmail] = useState("");
const [retws, setRetws] = useState("");
const [retwsb, setRetwsb] = useState("");
const navigate = useNavigate();

document.addEventListener("keypress", function(e) {
  if(e.key === 'Enter') {
    recuperar();    
  }
});

async function recuperar(){
    setRetws("");
    setRetwsb("");

    if (email === ""){
      setRetws("Informe o email!");
      return;
    };

    try {
      const response = await api.post('/accounts/forgot-password',{
        "Email": email
      });

      //console.log(response);
      setRetwsb(response.data.message);
      setTimeout(() => navigate('/login'), 180000);
    } catch (error) {
      setRetws(error.response.data.message);
    }
  }; 

  const Resposta = () => {
    if (retws === "") return null;
  
    return (
      <label className="retlabelslogin">
            <h5 className="retlabelslogin">{retws}</h5>
      </label>
    );
  };

  const Respostab = () => {
    if (retwsb === "") return null;
  
    return (
      <label className="retlabelsloginb">
            <h5 className="retlabelsloginb">{retwsb}</h5>
      </label>
    );
  };

  return (
      <main className="loginform">
        <div className="loginquad">
          <img className="loginlogo" src={logo} alt="Logo" />
          <h2 className="logintitulo2">Recuperação de conta</h2>
          <label className="loginlabels">
               <strong className="loginlabels">E-mail</strong>
          </label>
          <input className="logininputs2"
          type="text"
          placeholder="Informe o seu e-mail"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          />

          <button className="loginbtn" onClick={recuperar}>
            RECUPERAR SENHA
          </button> 

          <Resposta />
          <Respostab />

        </div> 
      </main>
  );
}

export default Recuperarsenha;
