import React, {useState} from 'react';
import { PrimaryNav, MenuLink, Menu } from './NavElement';
import logo from './logo.png';
import {ImPlus} from 'react-icons/im';
/* import {AiFillHome} from 'react-icons/ai' */
import {BsFillClipboard2Fill,BsGearFill} from 'react-icons/bs';
import {BiLogOut} from 'react-icons/bi';
import './Nav.css';
import { slide as Hamburguer } from 'react-burger-menu';
import {useNavigate} from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  
  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

  function logoff(){
      sessionStorage.clear();
      timeout(2000);
      let path = '/login'; 
      navigate(path);
  }

  const [isOpen, setOpen] = useState(false)

  const handleIsOpen = () => {
    setOpen(!isOpen)
  }

  const closeSideBar = () => {
    setOpen(false)
  }

  return (
    <>
    
      <PrimaryNav>
        <img className="navlogo" src={logo} alt="Logo" />
        <Menu>
          <MenuLink to="/projetos" activeStyle>
          <BsFillClipboard2Fill className='navicones' size={20} color="#fff"/>
            PROJETOS
          </MenuLink>
          <MenuLink to="/escolhaproduto" activeStyle>
          <ImPlus className='navicones' size={20} color="#fff"/>
            NOVO PROJETO
          </MenuLink>
          <MenuLink to="/meusdados" activeStyle>
          <BsGearFill className='navicones' size={20} color="#fff"/>
            MEUS DADOS
          </MenuLink>
          <MenuLink onClick={logoff} to="/login" activeStyle>
          <BiLogOut className='navicones' size={20} color="#fff"/>
            SAIR
          </MenuLink>
        </Menu>
        <Hamburguer right isOpen={isOpen}
              onOpen={handleIsOpen}
              onClose={handleIsOpen}>
        <Menu isOpen={isOpen}
              onOpen={handleIsOpen}
              onClose={handleIsOpen}>
        <MenuLink onClick = {closeSideBar} to="/projetos" className="menu-item" activeStyle>
          <BsFillClipboard2Fill className='navicones' size={20} color="#fff"/>
            PROJETOS
          </MenuLink>
          <MenuLink onClick = {closeSideBar} to="/escolhaproduto" className="menu-item" activeStyle>
          <ImPlus className='navicones' size={20} color="#fff"/>
            NOVO PROJETO
          </MenuLink>
          <MenuLink onClick = {closeSideBar} to="/meusdados" className="menu-item" activeStyle>
          <BsGearFill className='navicones' size={20} color="#fff"/>
            MEUS DADOS
          </MenuLink>
          <MenuLink onClick={logoff} to="/login" className="menu-item" activeStyle>
          <BiLogOut className='navicones' size={20} color="#fff"/>
            SAIR
          </MenuLink>
          </Menu>
        </Hamburguer>
      </PrimaryNav>
    </>
  )
}
export default Navbar