import React, { useState, useEffect }  from 'react';
import styled from 'styled-components';
import './Projetos.css';
import DatePicker from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from 'react-datepicker';
import {FaSearch} from 'react-icons/fa';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import {AiFillEdit,AiOutlineCheckCircle} from 'react-icons/ai';
import {ImCancelCircle} from 'react-icons/im';
import {GrDocumentPdf} from 'react-icons/gr';
import api from '../services/api';
import Token from '../services/Token';
import {useNavigate} from "react-router-dom";
import { saveAs } from 'file-saver';
import SelectCustom from '../components/SelectCustom';

registerLocale('ptBR', ptBR);

const Button = styled.button`
  color: #fff;
  background: #005aab;
  font-size: 1.5em;
  margin: 0.25em;
  padding: 0.25em 1em;
  border: 2px solid #005aab;
  border-radius: 3px;
`;

const Input = styled.input`
  padding: 0.5em;
  margin: 0.5em;
  min-width: 200px;
  border-radius: 3px;
`;

const Wrapper = styled.div``; // Moved outside of Calendar component

const statusoptions = [
  'Ativo',
  'Finalizado',
  'Cancelado'
]

function Projetos(){

  const navigate = useNavigate();
  /*
  async function imprimir(param){

    if (param != null) {
      const token = sessionStorage.getItem('token');
      try {
        Token();
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        console.log("Executando consulta");
        const response = await api.get('projetos/projeto/'+param, config);
        
        const fileAsBase64 = response.data;
        console.log(fileAsBase64);
        const binaryString = window.atob(fileAsBase64);
        const bytes = new Uint8Array(binaryString.length);

        const file = new Blob([bytes.map((byte, i) => binaryString.charCodeAt(i))], {
          type: 'application/pdf',
        });
        
        const fileURL = URL.createObjectURL(file);
        
        window.open(fileURL);
      } catch (error) {
        console.log((error.response.data.message));
      }
    }
  }; */
  
  async function Finalizar(param){
    if (param != null) {
      const token = sessionStorage.getItem('token');
      try {
        Token();
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        //console.log("Executando consulta");
        const response = await api.put('projetos/finalizar/'+param,{
          "Status": "Finalizado"
        }, config);
        consultarproj();
       
      } catch (error) {
        //console.log((error.response.data.message));
      }
    }
  };

  async function Cancelar(param){
    if (param != null) {
      const token = sessionStorage.getItem('token');
      try {
        Token();
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        //console.log("Executando consulta");
        const response = await api.put('projetos/cancelar/'+param,{
          "Status": "Finalizado"
        }, config);
        consultarproj();
       
      } catch (error) {
        //console.log((error.response.data.message));
      }
    }
  };

  async function Ativar(param){
    if (param != null) {
      const token = sessionStorage.getItem('token');
      try {
        Token();
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        //console.log("Executando consulta");
        const response = await api.put('projetos/ativar/'+param,{
          "Status": "Ativo"
        }, config);
        consultarproj();
       
      } catch (error) {
        //console.log((error.response.data.message));
      }
    }
  };

  async function visualizar(param){

    if (param != null) {
      const token = sessionStorage.getItem('token');
      try {
        Token();
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        //console.log("Executando consulta");
        const response = await api.get('projetos/projeto/'+param, config);
        
        const fileAsBase64 = response.data;
        //console.log(fileAsBase64);
        const binaryString = window.atob(fileAsBase64);
        const bytes = new Uint8Array(binaryString.length);


        const file = new Blob([bytes.map((byte, i) => binaryString.charCodeAt(i))]);

        saveAs(file, 'Projeto'+param+'.pdf');

        /* const file = new Blob([response.data], {
          type: 'application/pdf',
        });
        
        const fileURL = URL.createObjectURL(file);
        
        window.open(fileURL); */
      } catch (error) {
        //console.log((error.response.data.message));
      }
    }
  };

  async function visualizar2(param){

    if (param != null) {
      const token = sessionStorage.getItem('token');
      try {
        Token();
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        //console.log("Executando consulta");
        const response = await api.get('projetos/projetovidros/'+param, config);
        const fileAsBase64 = response.data;
        //console.log(fileAsBase64);
        const binaryString = window.atob(fileAsBase64);
        const bytes = new Uint8Array(binaryString.length);
        const file = new Blob([bytes.map((byte, i) => binaryString.charCodeAt(i))]);
        saveAs(file, 'Projeto'+param+'_vidros.pdf');
      } catch (error) {
        //console.log((error.response.data.message));
      }
      try {
        Token();
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        //console.log("Executando consulta");
        const response = await api.get('projetos/projetoprodutos/'+param, config);
        const fileAsBase64 = response.data;
        //console.log(fileAsBase64);
        const binaryString = window.atob(fileAsBase64);
        const bytes = new Uint8Array(binaryString.length);
        const file = new Blob([bytes.map((byte, i) => binaryString.charCodeAt(i))]);
        saveAs(file, 'Projeto'+param+'_produtos.pdf');
      } catch (error) {
        //console.log((error.response.data.message));
      }
    }
  };
  
  const editar = (event, param, param2, param3) => {
    var path = "";
    if (param3 !== "" || param2!== ""){
      path = '/novoprojeto?projeto='+param+'&cliente='+param2+'&obra='+param3;
    } 
    else if (param3 !== ""){
      path = '/novoprojeto?projeto='+param+'&obra='+param3;
    }
    else if (param2  !== ""){
      path = '/novoprojeto?projeto='+param+'&cliente='+param2;
    }
    else {
      path = '/novoprojeto?projeto='+param; 
    }
    navigate(path);
  };

  document.body.classList.replace(document.body.className,'projmainbody');

  const [d1, setD1] = useState("");
  const [d2, setD2] = useState("");
  const [filtro, setFiltro]  = useState("");
  const [filtrocli, setFiltrocli]  = useState("");
  const [status, setStatus]  = useState("Ativo");
  //DADOS OBTER DA API
  const [data, setData]  = useState(JSON.parse('[{"ID": null,"DATAPEDIDO": "","OBRA": "","ITEM": "","OBS": ""}]'));

  async function consultarproj(){
    const token = sessionStorage.getItem('token');
    try {
      Token();
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const response = await api.post('projetos/consulta',{
        "D1": d1,
        "D2": d2,
        "Cliente": filtrocli,
        "Obra": filtro,
        "Status": status
      }, config);
      const bakery = response.data[0];
      if (bakery){
        setData(JSON.parse(JSON.stringify(response.data)));
      }
      else
      {
        setData(JSON.parse('[{"ID": null,"DATAPEDIDO": "","CLIENTE": "","OBRA": "","ITEM": "","OBS": "","STATUS": ""}]'));
      }
      //console.log("Executando consulta");
      //setData(JSON.parse('[{"ID": 1,"DATAPEDIDO": "2023-05-08T00:00:00","OBRA": "Teste","ITEM": "JANELA MAX SYSTEM largura do vão: 2000mm altura do vão: 1000mm folhas: 2 cor: Branco","OBS": "Obs de teste"},{"ID": 2,"DATAPEDIDO": "2023-05-08T00:00:00","OBRA": "Teste","ITEM": "JANELA MAX SYSTEM largura do vão: 2000mm altura do vão: 1000mm folhas: 2 cor: Branco","OBS": "Obs de teste"}]'));
    } catch (error) {
      //console.log((error.response.data.message));
    }
  }

  async function gerarotimizado(){
    const token = sessionStorage.getItem('token');
    try {
      Token();
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const response = await api.post('projetos/gerarprojetootimizado',{
        "D1": d1,
        "D2": d2,
        "Cliente": filtrocli,
        "Obra": filtro
      }, config);
      const fileAsBase64 = response.data;
      const binaryString = window.atob(fileAsBase64);
      const bytes = new Uint8Array(binaryString.length);
      const file = new Blob([bytes.map((byte, i) => binaryString.charCodeAt(i))]);

      saveAs(file, 'Projeto_otimizado.pdf');
    } catch (error) {
      //console.log((error.response.data.message));
    }
  }

  async function gerarotimizado2(){
    const token = sessionStorage.getItem('token');
    try {
      Token();
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const response = await api.post('projetos/gerarprojetootimizadov',{
        "D1": d1,
        "D2": d2,
        "Cliente": filtrocli,
        "Obra": filtro
      }, config);
      const fileAsBase64 = response.data;
      const binaryString = window.atob(fileAsBase64);
      const bytes = new Uint8Array(binaryString.length);
      const file = new Blob([bytes.map((byte, i) => binaryString.charCodeAt(i))]);

      saveAs(file, 'Projeto_otimizadovidros.pdf');
    } catch (error) {
      //console.log((error.response.data.message));
    }
    try {
      Token();
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const response = await api.post('projetos/gerarprojetootimizadop',{
        "D1": d1,
        "D2": d2,
        "Cliente": filtrocli,
        "Obra": filtro
      }, config);
      const fileAsBase64 = response.data;
      const binaryString = window.atob(fileAsBase64);
      const bytes = new Uint8Array(binaryString.length);
      const file = new Blob([bytes.map((byte, i) => binaryString.charCodeAt(i))]);

      saveAs(file, 'Projeto_otimizadoprodutos.pdf');
    } catch (error) {
      //console.log((error.response.data.message));
    }
  }

  useEffect(() => {
    let ignore = false;

    async function consultarini(){
      const token = sessionStorage.getItem('token');
      try {
        Token();
        if (!token){
          navigate('/login');
        }
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        const response = await api.post('projetos/consulta',{
          "D1": "",
          "D2": "",
          "Cliente": "",
          "Obra": "",
          "Status": status
        }, config);
        const bakery = response.data[0];
        if (bakery){
          setData(JSON.parse(JSON.stringify(response.data)));
        }
        else
        {
          setData(JSON.parse('[{"ID": null,"DATAPEDIDO": "","CLIENTE": "","OBRA": "","ITEM": "","OBS": "","STATUS": ""}]'));
        }
        //console.log("Executando consulta");
        //setData(JSON.parse('[{"ID": 1,"DATAPEDIDO": "2023-05-08T00:00:00","OBRA": "Teste","ITEM": "JANELA MAX SYSTEM largura do vão: 2000mm altura do vão: 1000mm folhas: 2 cor: Branco","OBS": "Obs de teste"},{"ID": 2,"DATAPEDIDO": "2023-05-08T00:00:00","OBRA": "Teste","ITEM": "JANELA MAX SYSTEM largura do vão: 2000mm altura do vão: 1000mm folhas: 2 cor: Branco","OBS": "Obs de teste"}]'));
      } catch (error) {
        //console.log((error.response.data.message));
      }
    }
    
    if (!ignore)  consultarini()
    return () => { ignore = true; }
    },[]
  );

    //TABELA
/*
var data = [
  {id: 15478, datapedido:'17/04/2023', obra:'Cliente XYZ', item: 'AL Porta MaxSystem largura do vão: 2000mm Altura do vao: 3000mm 3 folhas', obs: 'Obs teste 1'},
  {id: 15433, datapedido:'15/04/2023', obra:'Teste obra', item: 'AL Janela MaxSystem largura do vão: 1000mm Altura do vao: 1000mm 2 folhas', obs: ''},
  {id: 15322, datapedido:'06/04/2023', obra:'Zezinho', item: 'AL Porta MaxSystem largura do vão: 2000mm Altura do vao: 3000mm 3 folhas', obs: 'Obs teste 3'}
] */

const Head = ({ keys, head}) => {
  return(
    <Thead>
        <Tr>
          {
            keys.map(key => <Th key={key}>{head[key] || key}</Th>)
          }
          <Th>Opções</Th> 
        </Tr>  
    </Thead>
  )  
}

const Row = ({ record }) => {
  const keys = Object.keys(record)
  return(
    <Tr key={record.id}>
      {
        keys.map(key => <Td key={key}>{record[key]}</Td>)
      }
      <Td>
        <div>
          <button className='btinvisivel' onClick={event => editar(event, record.ID, record.CLIENTE, record.OBRA)}>
            <AiFillEdit className='icones' size={20} color="#000"/>
            Editar
          </button>
        </div>
        <div>
          <button className='btinvisivel' onClick={event => visualizar(record.ID)}>
            <GrDocumentPdf className='icones' size={20} color="#000"/>
            Completo
          </button>
        </div>
        <div>
          <button className='btinvisivel' onClick={event => visualizar2(record.ID)}>
            <GrDocumentPdf className='icones' size={20} color="#000"/>
            Separado
          </button>
        </div>
        {record.STATUS === "Ativo" && (
        <div>
          <button className='btinvisivel' onClick={event => Finalizar(record.ID)}>
            <AiOutlineCheckCircle className='icones' size={20} color="#000"/>
            Finalizar
          </button>
        </div>)}
        {record.STATUS === "Ativo" && (
        <div>
          <button className='btinvisivel' onClick={event => Cancelar(record.ID)}>
            <ImCancelCircle className='icones' size={20} color="#000"/>
            Cancelar
          </button>
        </div>)}
        {record.STATUS !== "Ativo" && (
        <div>
          <button className='btinvisivel' onClick={event => Ativar(record.ID)}>
            <AiOutlineCheckCircle className='icones' size={20} color="#000"/>
            Reativar
          </button>
        </div>)}
      </Td>
    </Tr> 
  )
}

const TableC = ({data, head}) => {
  const keys = Object.keys(data[0])
  return(
    <Table>
      <Head keys={keys} head={head}>
      </Head>
      <Tbody>
        {data.map(record => <Row record={record} />) }
      </Tbody>
    </Table>
  )
}
//FIM TABELA


//CHAMADA TABELA

function TableExample(props) {

  const head = {
    ID: 'Nº',
    DATAPEDIDO: 'Data',
    CLIENTE: 'Cliente',
    OBRA: 'Obra',
    ITEM: 'Item',
    OBS: 'Observações',
    STATUS: 'Status'
  }
  return(
    <TableC data={data} head={head} />
  )
}
//FIM CHAMADA TABELA

  return (
    <main className='projmain'>
      <h2 className='projtitulo'>Consulta de projetos</h2>
      <div className='divbuscamain'>
        <Wrapper className='projwrapper'>
          <div className='projdiv'>
            <label className='projlabels'>Data inicial:</label>
            <DatePicker className='projdata'
              onChange={date => setD1(date)}
              selected={d1}
              locale="ptBR"
              dateFormat="dd/MM/yyyy"
              autoComplete='off'
            />
          </div>
          <div className='projdiv'>
            <label className='projlabels'>Data final:</label>
            <DatePicker className='projdata'
              onChange={date => setD2(date)}
              selected={d2}
              locale="ptBR"
              dateFormat="dd/MM/yyyy"
              autoComplete='off'
            />
          </div>
        </Wrapper>
        
        <div className='projdiv'>
          <label className='projlabels2'>Cliente:</label>
          <Input className="projinputs"
            value={filtrocli}
            onChange={(event) => setFiltrocli(event.target.value)}
            placeholder='Informe o cliente' type="text" 
            autoComplete='off'
          />
        </div>

        <div className='projdiv'>
          <label className='projlabels2'>Nome da obra:</label>
          <Input className="projinputs"
            value={filtro}
            onChange={(event) => setFiltro(event.target.value)}
            placeholder='Informe o nome da obra' type="text" 
            autoComplete='off'
          />
        </div>
        
        <div className='projdiv'>
        <label className='projlabels3'>Filtro por status:</label>
        <SelectCustom
                options={statusoptions}
                onItemSelected={setStatus}
                defaultValue={status}
        />
        </div>

        <Button onClick={consultarproj}>
          <FaSearch className='icones' size={20} color="#fff"/>
          FILTRAR
        </Button>
      </div>
      <div className='projtab'>
      <TableExample >
       </TableExample>
      </div>
      {status === "Ativo" && (
          <Button onClick={gerarotimizado}>
           PROJETO(S) OTIMIZADO
          </Button>
      )}
      {status === "Ativo" && (
          <Button onClick={gerarotimizado2}>
            PROJETO(S) OTIMIZADO SEPARADO
          </Button>
      )}
    </main>
  )
}
export default Projetos