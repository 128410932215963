import React from 'react';
import styled from 'styled-components';
import './Escolhaproduto.css';
import janela from './img/janela.png';
import porta from './img/porta.png';
import portapivotante from './img/portapivotante.png';
import {useNavigate} from "react-router-dom";
import Pdf from './MaxSystem.pdf';

const Button = styled.button`
  color: #fff;
  background: #005aab;
  font-size: 1em;
  margin: 0.7em;
  padding: 0.25em 1em;
  border: 2px solid #005aab;
  border-radius: 3px;
  height: 40px;
`;

function Escolhaproduto(){

  const navigate = useNavigate();

  function Janela(){
    let path = '/novoprojeto?produto=1'; 
    navigate(path);
  };

  function Porta(){
    let path = '/novoprojeto?produto=2'; 
    navigate(path);
  };

  function Portapivo(){
    let path = '/novoprojeto?produto=3'; 
    navigate(path);
  };  

  function Documentacao(){
    window.open(Pdf);
  };  

  document.body.classList.replace(document.body.className,'bodyescolhaproduto');

  return (
    <main className='escolhaproduto'>
    <h1 className='escolhaprodutotitulo'>Selecione o produto desejado:</h1>
    <img class='escolhaprodutoimg' src={janela} alt="janela" onClick={Janela} />
    <img class='escolhaprodutoimg' src={porta} alt="porta" onClick={Porta} />
    <img class='escolhaprodutoimg' src={portapivotante} alt="portapivo" onClick={Portapivo} />
    <h1 className='escolhaprodutotitulo'>Documentação técnica:</h1>
    <div classname='escolhaprodutodiv'>
      <Button type="button" onClick={Documentacao}>CLIQUE AQUI PARA VISUALIZAR</Button>
    </div>
    </main> 
  )
}
export default Escolhaproduto