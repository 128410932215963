import React from "react";
import './Cadastro.css';
import logo from '../logo.png';
import { useNavigate } from "react-router-dom";

function Cadastroconcluido() {

  document.body.classList.replace(document.body.className,'cadform');

  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = '../login'; 
    navigate(path);
  }

  return (
      <main className="cadform">
        <div className="cadconcluidoquad">
          <img className="loginlogo" src={logo} alt="Logo" />

          <h1 className="cadtitulo">Cadastro concluído</h1>

          <label className="cadlabels">
               <strong className="cadlabels">Um link de confirmação foi enviado para o seu email, verifique seu e-mail e acesse o link ativar sua conta!</strong>
          </label>

          <button className="cadbtn" onClick={routeChange}>
            OK
          </button>
        </div> 
      </main>
  );
}

export default Cadastroconcluido;
