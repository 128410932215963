import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider} from "react-router-dom";
import Login from './pages/Login';
import Cadastro from './pages/Cadastro';
import Erro from './pages/Erro';
import Recuperarsenha from './pages/Recuperarsenha';
import Resetarsenha from './pages/Resetarsenha';
import Cadastroconcluido from './pages/Cadastroconcluido';
import Principal from './pages/Principal';
import Escolhaproduto from './pages/Escolhaproduto';
import Novoprojeto from './pages/Novoprojeto';
import Meusdados from './pages/Meusdados';
import Projetos from './pages/Projetos';
import Cadastroverificacao from './pages/Cadastroverificacao';

export function Verificaauth(Pagina) {
  const token = sessionStorage.getItem('token');
  //console.log("Verificando auth");
  //console.log (token);
  if (token!==null) {
  //  console.log("not null");
    return <Pagina />;
  }
  else {
  //  console.log("null");
    return <Login />;
  }
}

const router = createBrowserRouter([
  
  {
    path: "/",
    element: Verificaauth(Projetos)
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/cadastro",
    element: <Cadastro />
  },
  {
    path: "/recuperarsenha",
    element: <Recuperarsenha />
  },
  {
    path: "/account/verify-email",
    element: <Cadastroverificacao />
  },
  {
    path: "/account/reset-password",
    element: <Resetarsenha />
  },
  {
    element: <Principal />,
    children: [
      {
          path: '/painel',
          element: <Principal /> 
      },
      {
          path: 'projetos',
          element: <Projetos />,
      },
      {
          path: 'escolhaproduto',
          element: <Escolhaproduto />,
      },
      {
          path: 'novoprojeto',
          element: <Novoprojeto />,
      },
      {
          path: 'meusdados',
          element: <Meusdados />,
      }
    ]
  },
  {
    path: "/cadastroconcluido",
    element: <Cadastroconcluido />
  },
  {
    path: "*",
    element: <Erro />
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);