import api from '../services/api';

function setToken(userToken) {
    sessionStorage.setItem('token', userToken);
    var d1 = new Date ();
    var d2 = new Date ( d1 );
    d2.setMinutes ( d1.getMinutes() + 170 );
    sessionStorage.setItem('tokendatahora', d2);
};

async function gettoken(){
   var datahora = new Date();
   //console.log('Verificando a validade do token');
   //console.log(Date.parse(sessionStorage.getItem('tokendatahora')));
   //console.log(Date.parse(datahora));
   if (sessionStorage.getItem('tokendatahora')!= null & Date.parse(datahora)>Date.parse(sessionStorage.getItem('tokendatahora'))){
    //no caso do token expirar devo fazero refresh, limpando o existente e colocando novamente
    try {
        //console.log('Fazendo o refresh do token');
        const response = await api.post('/accounts/refresh-token');
        //console.log(response);
        sessionStorage.removeItem('tokendatahora');
        sessionStorage.removeItem('token');
        setToken(response.data.jwtToken);

      } catch (error) {
        //caso o refresh nao funcione vou limpar todo o session storage
        sessionStorage.clear();
        //console.log(error.response.data.message);
      }
   }

};

export default gettoken;