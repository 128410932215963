import React, { useState } from "react";
import './Cadastro.css';
import logo from '../logo.png';
import { useNavigate } from "react-router-dom";
import api from '../services/api';

function Cadastroverificacao() {

  document.body.classList.replace(document.body.className,'cadform');
  let navigate = useNavigate(); 

  const [retws, setRetws] = useState("");

  const Resposta = () => {
    if (retws === "") return null;
  
    return (
      <label className="retlabels">
            <h5 className="retlabels">{retws}</h5>
      </label>
    );
  };

  document.addEventListener("keypress", function(e) {
    if(e.key === 'Enter') {
      verificarEmail();    
    }
  });

  async function verificarEmail() { 
    setRetws("");
    try {
      const response = await api.post('/accounts/verify-email',{
        "token": token
      });
      //console.log(response.data.message);
      let path = '../login'; 
      navigate(path);
    }catch (error) {
      //console.log(error);
      setRetws(error.response.data.message);
    }
  }

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');
  //console.log(token);

  return (
      <main className="cadform">
        <div className="cadconcluidoquad">
          <img className="loginlogo" src={logo} alt="Logo" />

          <h1 className="cadtitulo">Verificação de e-mail</h1>

          <label className="cadlabels">
               <strong className="cadlabels">Clique no link abaixo para efetuar a verificação</strong>
          </label>

          <Resposta />

          <button className="cadbtn2" onClick={verificarEmail}>
            VERIFICAR E-MAIL
          </button>
        </div> 
      </main>
  );
}

export default Cadastroverificacao;
